<template>
  <g id="g1468"
    ><g id="g1470" clip-path="url(#clipPath1474)"
      ><path
        d="m 3423,11449.5 c 0,-17.949 14.5508,-32.5 32.5,-32.5 17.9492,0 32.5,14.551 32.5,32.5 0,17.949 -14.5508,32.5 -32.5,32.5 -17.9492,0 -32.5,-14.551 -32.5,-32.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1476"/><path
        d="m 3310,11449.5 c 0,-17.949 14.5508,-32.5 32.5,-32.5 17.9492,0 32.5,14.551 32.5,32.5 0,17.949 -14.5508,32.5 -32.5,32.5 -17.9492,0 -32.5,-14.551 -32.5,-32.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1478"/><path
        d="m 8956,12163.5 c 0,-41.697 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.803 75.5,75.5 0,41.697 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.803 -75.5,-75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1480"/><path
        d="m 8634,11445.5 c 0,31.204 -25.2959,56.5 -56.5,56.5 -31.2041,0 -56.5,-25.296 -56.5,-56.5 0,-31.204 25.2959,-56.5 56.5,-56.5 31.2041,0 56.5,25.296 56.5,56.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1482"/><path
        d="m 8578,1938.5 c 0,-20.7107 16.7891,-37.5 37.5,-37.5 20.7109,0 37.5,16.7893 37.5,37.5 0,20.7107 -16.7891,37.5 -37.5,37.5 -20.7109,0 -37.5,-16.7893 -37.5,-37.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1484"/><path
        d="m 9447,1938.5 c 0,-20.7107 16.7891,-37.5 37.5,-37.5 20.7109,0 37.5,16.7893 37.5,37.5 0,20.7107 -16.7891,37.5 -37.5,37.5 -20.7109,0 -37.5,-16.7893 -37.5,-37.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1486"/><path
        d="m 9611,2713.5 c 0,27.8904 -22.6094,50.5 -50.5,50.5 -27.8906,0 -50.5,-22.6096 -50.5,-50.5 0,-27.8904 22.6094,-50.5 50.5,-50.5 27.8906,0 50.5,22.6096 50.5,50.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1488"/><path
        d="m 8880,7401.5 c 0,-146.0791 118.4209,-264.5 264.5,-264.5 146.0791,0 264.5,118.4209 264.5,264.5 0,146.0791 -118.4209,264.5 -264.5,264.5 -146.0791,0 -264.5,-118.4209 -264.5,-264.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1490"/><path
        d="m 2681,7401.5 c 0,-146.0791 118.4207,-264.5 264.5,-264.5 146.0794,0 264.5,118.4209 264.5,264.5 0,146.0791 -118.4206,264.5 -264.5,264.5 -146.0793,0 -264.5,-118.4209 -264.5,-264.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1492"/><path
        d="m 9220,10708.5 c 0,-41.697 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.803 75.5,75.5 0,41.697 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.803 -75.5,-75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1494"/><path
        d="m 9220,3375.5 c 0,-41.6975 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8025 75.5,75.5 0,41.6975 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8025 -75.5,-75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1496"/><path
        d="m 2719,3375.5 c 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5 0,41.6975 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8025 -75.5,-75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1498"/><path
        d="m 2719,10708.5 c 0,-41.697 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.803 75.5,75.5 0,41.697 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.803 -75.5,-75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1500"/><path
        d="m 9305,10292.5 c 0,88.642 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.858 -160.5,-160.5 0,-88.642 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.858 160.5,160.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1502"/><path
        d="m 3106,3791.5 c 0,88.6416 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.8584 -160.5,-160.5 0,-88.6416 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.8584 160.5,160.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1504"/><path
        d="m 3106,10292.5 c 0,88.642 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.858 -160.5,-160.5 0,-88.642 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.858 160.5,160.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1506"/><path
        d="m 9305,3791.5 c 0,88.6416 -71.8584,160.5 -160.5,160.5 -88.6416,0 -160.5,-71.8584 -160.5,-160.5 0,-88.6416 71.8584,-160.5 160.5,-160.5 88.6416,0 160.5,71.8584 160.5,160.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1508"/><path
        d="m 9069,6720.5 c 0,-41.6973 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8027 75.5,75.5 0,41.6973 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8027 -75.5,-75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1510"/><path
        d="m 2870,6720.5 c 0,-41.6973 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8027 75.5,75.5 0,41.6973 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8027 -75.5,-75.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1512"/><path
        d="m 6697,1617 v -91"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1514"/><g id="g1516" transform="rotate(180,3405.25,763.25)"
        ><path
          d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path1518"/></g
      ><path
        d="M 9522,1413 H 6810"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1520"/><path
        d="m 9522.5,1414 c 125.0928,0 226.5,101.4075 226.5,226.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1522"/><path
        d="M 9749,12390 V 1640"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1524"/><path
        d="m 9749,12390.5 c 0,125.093 -101.4072,226.5 -226.5,226.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1526"/><path
        d="M 2567,12617 H 9522"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1528"/><path
        d="m 2567.5,12617 c -125.0925,0 -226.5,-101.407 -226.5,-226.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1530"/><path
        d="M 2340,1640 V 12390"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1532"/><path
        d="m 2341,1640.5 c 0,-125.0925 101.4075,-226.5 226.5,-226.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1534"/><path
        d="M 5279,1413 H 2567"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1536"/><g id="g1538" transform="rotate(-90,3403,-1876.5)"
        ><path
          d="M 113.5,0 C 113.5,62.68432 62.68432,113.5 0,113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path1540"/></g
      ><path
        d="m 5392,1526 v 91"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1542"/><path
        d="m 5506.5,1731 c -62.6841,0 -113.5,-50.8157 -113.5,-113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1544"/><path
        d="M 5506,1731 H 6583"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1546"/><path
        d="m 6697,1617.5 c 0,62.6843 -50.8159,113.5 -113.5,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1548"/><path
        d="m 8993,3791.5 c 0,-83.6711 67.8291,-151.5 151.5,-151.5 83.6709,0 151.5,67.8289 151.5,151.5 0,83.6711 -67.8291,151.5 -151.5,151.5 -83.6709,0 -151.5,-67.8289 -151.5,-151.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1550"/><path
        d="m 9207,3791.5 c 0,34.5178 -27.9824,62.5 -62.5,62.5 -34.5176,0 -62.5,-27.9822 -62.5,-62.5 0,-34.5178 27.9824,-62.5 62.5,-62.5 34.5176,0 62.5,27.9822 62.5,62.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1552"/><path
        d="m 8993,10292.5 c 0,-83.671 67.8291,-151.5 151.5,-151.5 83.6709,0 151.5,67.829 151.5,151.5 0,83.671 -67.8291,151.5 -151.5,151.5 -83.6709,0 -151.5,-67.829 -151.5,-151.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1554"/><path
        d="m 9207,10292.5 c 0,34.518 -27.9824,62.5 -62.5,62.5 -34.5176,0 -62.5,-27.982 -62.5,-62.5 0,-34.518 27.9824,-62.5 62.5,-62.5 34.5176,0 62.5,27.982 62.5,62.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1556"/><path
        d="m 2794,3791.5 c 0,-83.6711 67.8289,-151.5 151.5,-151.5 83.6711,0 151.5,67.8289 151.5,151.5 0,83.6711 -67.8289,151.5 -151.5,151.5 -83.6711,0 -151.5,-67.8289 -151.5,-151.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1558"/><path
        d="m 3008,3791.5 c 0,34.5178 -27.9822,62.5 -62.5,62.5 -34.5178,0 -62.5,-27.9822 -62.5,-62.5 0,-34.5178 27.9822,-62.5 62.5,-62.5 34.5178,0 62.5,27.9822 62.5,62.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1560"/><path
        d="m 2794,10292.5 c 0,-83.671 67.8289,-151.5 151.5,-151.5 83.6711,0 151.5,67.829 151.5,151.5 0,83.671 -67.8289,151.5 -151.5,151.5 -83.6711,0 -151.5,-67.829 -151.5,-151.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1562"/><path
        d="m 3008,10292.5 c 0,34.518 -27.9822,62.5 -62.5,62.5 -34.5178,0 -62.5,-27.982 -62.5,-62.5 0,-34.518 27.9822,-62.5 62.5,-62.5 34.5178,0 62.5,27.982 62.5,62.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1564"/><path
        d="m 8993,3791.5 c 0,-83.6711 67.8291,-151.5 151.5,-151.5 83.6709,0 151.5,67.8289 151.5,151.5 0,83.6711 -67.8291,151.5 -151.5,151.5 -83.6709,0 -151.5,-67.8289 -151.5,-151.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1566"/><path
        d="m 9207,3791.5 c 0,34.5178 -27.9824,62.5 -62.5,62.5 -34.5176,0 -62.5,-27.9822 -62.5,-62.5 0,-34.5178 27.9824,-62.5 62.5,-62.5 34.5176,0 62.5,27.9822 62.5,62.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path1568"/></g
  ></g>
</template>
<script>
export default {
  name: "IngunMa11Bottom",
};
</script>
